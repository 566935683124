export default [
  {
    path: '/valid-hs-codes',
    name: 'valid-hs-codes',
    component: () => import('@/views/valid_hs_codes/Index.vue'),
    meta: {
      pageTitle: 'Valid hs codes',
      roles: ['Feeport Admin'],
    },
  },
]
